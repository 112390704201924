import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import SEO from '@components/seo';
import MainLayout from '@layouts/mainLayout';
import PriceSection from '@components/pages/pricing/price';
import HelpSection from '@components/pages/pricing/help';

class PricingPage extends Component {
  getSectionContent = (section) => {
    const { data } = this.props;
    return data.cockpitpricing[section].childMarkdownRemark.html;
  }

  render() {
    const { data } = this.props;
    return (
      <MainLayout>
        {/* TODO: add keywords */}
        <SEO title="Pricing" keywords={[]} />
        <PriceSection content={this.getSectionContent('price')} data={data.allCockpitcourses.edges} />
        <HelpSection content={this.getSectionContent('help')} />
      </MainLayout>
    );
  }
}

PricingPage.propTypes = {
  data: PropTypes.object,
};

export default PricingPage;

export const query = graphql`
  query PricingQuery {
    cockpitpricing {
      price: childCockpitpricingPriceTextNode {
        childMarkdownRemark {
          html
        }
      }
      help: childCockpitpricingHelpTextNode {
        childMarkdownRemark {
          html
        }
      }
    }
    allCockpitcourses {
      edges {
        node {
          entry {
            courseType
            payment {
              monthly
              annually
            }
          }
        }
      }
    }
  }
`;
