import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Text, { TEXT_COLOR } from './text';

const Wrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  '@media (max-width: 480px)': {
    justifyContent: 'center',
  },
},
({ margin }) => ({
  margin: margin || '0',
}));

const TextWrapper = styled.div({
  cursor: 'pointer',
  '@media (max-width: 480px)': {
    width: 103,
    height: 31,
    margin: '0 5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& span.trigger-button-text': {
      fontSize: 12,
    },
  },
},
({ theme, isActive }) => ({
  pointerEvents: isActive ? 'none' : 'all',
  '@media (max-width: 480px)': {
    boxShadow: theme.shadows.triggerMobile,
  },
}));

const StyledTrigger = styled.div({
  width: 54,
  height: 24,
  margin: '0 16px',
  position: 'relative',
  borderRadius: 12,
  cursor: 'pointer',
  '&:after': {
    content: '""',
    display: 'block',
    width: 18,
    height: 18,
    borderRadius: '50%',
    position: 'absolute',
    top: 3,
  },
  '@media (max-width: 480px)': {
    display: 'none',
  },
},
({ theme, triggerState }) => ({
  backgroundColor: theme.colors.triggerBackground,
  '&:after': {
    left: triggerState ? 'auto' : '8%',
    right: triggerState ? '8%' : 'auto',
    backgroundColor: theme.colors.textHighlighted,
    boxShadow: theme.shadows.triggerButton,
  },
}));


const TriggerButton = ({ triggerState, handleTrigger, margin }) => (
  <Wrapper margin={margin}>
    <TextWrapper isActive={!triggerState} onClick={handleTrigger}>
      <Text fontSize={14} color={TEXT_COLOR.DARK_GRAY} style={{ opacity: triggerState ? 0.3 : 1 }} className="trigger-button-text">
        Billed Monthly
      </Text>
    </TextWrapper>
    <StyledTrigger triggerState={triggerState} onClick={handleTrigger} className="trigger-button" />
    <TextWrapper isActive={triggerState} onClick={handleTrigger}>
      <Text fontSize={14} color={TEXT_COLOR.DARK_GRAY} style={{ opacity: triggerState ? 1 : 0.3 }} className="trigger-button-text">
        Billed Annually
      </Text>
    </TextWrapper>
  </Wrapper>
);

TriggerButton.propTypes = {
  triggerState: PropTypes.bool,
  handleTrigger: PropTypes.func,
  margin: PropTypes.string,
};

export default TriggerButton;
