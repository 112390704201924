import styled from '@emotion/styled';
import Text from '@components/text';
import { COURSE_TYPES } from '@utils/constants';

export const Wrapper = styled.section({
  width: '100%',
  paddingTop: 60,
  marginBottom: 80,
  '@media (max-width: 480px)': {
    marginBottom: 96,
  },
});

export const ContentWrapper = styled.div({
  margin: '0 9.5%',
  textAlign: 'center',
});

export const TextContainer = styled.div({
  '@media (max-width: 480px)': {
    marginBottom: 26,
  },
},
({ theme }) => ({
  '& h2': {
    color: theme.colors.textHighlighted,
  },
}));

export const PathsContainer = styled.div({
  marginTop: 20,
  display: 'grid',
  gridGap: 18,
  gridTemplateColumns: '1fr 1fr 1fr',
  alignItems: 'stretch',
  '@media (max-width: 480px)': {
    display: 'flex',
    flexDirection: 'column',
  },
});

export const PathInfo = styled.div({
  width: '70%',
  '& h4': {
    fontWeight: 'bold',
    marginBottom: 14,
  },
  '& p': {
    fontSize: 18,
    marginBottom: 36,
  },
  '@media (max-width: 480px)': {
    '& h4': {
      marginBottom: 8,
    },
    '& p': {
      fontSize: 12,
      marginBottom: 14,
    },
  },
},
({ theme }) => ({
  '& h4': {
    color: theme.colors.textHighlighted,
  },
  '& p': {
    color: theme.colors.textGray,
  },
}));

export const PathOptions = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '@media (max-width: 480px)': {
    marginBottom: 23,
  },
});

export const StyledImage = styled.img({
  alignSelf: 'end',
  marginBottom: 24,
  '@media (max-width: 480px)': {
    marginBottom: 12,
    alignSelf: 'center',
  },
},
({ type }) => ({
  height: type === COURSE_TYPES.JUNIOR ? 76 : 96,
  '@media (max-width: 480px)': {
    height: type === COURSE_TYPES.JUNIOR ? 46 : 56,
    marginTop: type === COURSE_TYPES.JUNIOR ? 10 : 0,
  },
}));

export const Price = styled(Text)({
  fontSize: 21,
  marginBottom: 5,
});

export const PriceText = styled(Text)({
  '@media (max-width: 480px)': {
    fontSize: 18,
  },
});

export const Path = styled.div({
  padding: '25px 10px',
  // margin: '45px 0',
  display: 'grid',
  // gridTemplateRows: '140px 140px 137px 53px',
  justifyItems: 'center',
  borderRadius: 5,
  // opacity: 0.7,
  // '& h4': {
  //   fontSize: 18,
  // },
  // '&:hover': {
  alignSelf: 'center',
  margin: 0,
  opacity: 1,
  gridTemplateRows: '140px auto 180px 53px',
  '& h4': {
    fontSize: 21,
  },
  '& .trigger-button': {
    width: 59,
    height: 30,
    borderRadius: 15,
    '&:after': {
      width: 22,
      height: 22,
      top: 4,
    },
  },
  '& .trigger-button-text': {
    fontSize: 18,
  },
  [Price]: {
    fontSize: 52,
    marginBottom: 0,
  },
  // },
  '@media (max-width: 480px)': {
    padding: '15px 20px 32px',
    margin: '0 0 34px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    opacity: 1,
    [Price]: {
      fontSize: 25,
      marginBottom: -5,
      marginTop: -5,
    },
    '& a': {
      width: 130,
      height: 32,
      lineHeight: '28px',
      fontSize: 12,
    },
    '& a:hover': {
      lineHeight: '32px',
    },
    '&:hover': {
      margin: '0 0 34px 0',
      alignSelf: 'auto',
      '& h4': {
        fontSize: 18,
      },
      '& .trigger-button': {
        display: 'none',
      },
      '& .trigger-button-text': {
        fontSize: 12,
      },
      [Price]: {
        fontSize: 25,
        marginBottom: -5,
        marginTop: -5,
      },
    },
  },
},
({ theme, type }) => ({
  backgroundColor: theme.colors.background,
  // boxShadow: theme.shadows.pathItem,
  boxShadow: theme.shadows.pathItemHovered,
  '@media (max-width: 480px)': {
    order: type === COURSE_TYPES.NOVOTALK ? -1 : 'initial',
  },
}));
