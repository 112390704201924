import styled from '@emotion/styled';

export const Wrapper = styled.section({
  width: '100%',
  marginBottom: 145,
  '@media (max-width: 480px)': {
    marginBottom: 70,
  },
});

export const ContentWrapper = styled.div({
  padding: '0 9.5%',
  textAlign: 'center',
  '& h2': {
    width: '40%',
    margin: '0 auto 20px auto',
    fontSize: 34,
  },
  '& h4': {
    marginBottom: 32,
  },
  '@media (max-width: 480px)': {
    '& h2': {
      width: '100%',
      marginBottom: 28,
      fontSize: 25,
      lineHeight: '29px',
    },
  },
},
({ theme }) => ({
  '& h2': {
    color: theme.colors.textHighlighted,
  },
  '& h4': {
    color: theme.colors.textGray,
  },
}));

export const StyledForm = styled('form')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '@media (max-width: 480px)': {
    '& button': {
      minWidth: 69,
      maxWidth: 69,
      height: 32,
      fontSize: 12,
    },
  },
});

export const StyledInput = styled.input({
  width: 457,
  height: 32,
  marginRight: 17,
  padding: '0 10px',
  borderRadius: 5,
  border: '1px solid',
  outline: 'none',
  fontSize: 17,
  '@media (max-width: 480px)': {
    fontSize: 15,
    width: '101%',
    marginRight: '-1%',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
},
({ theme, hasError, disabled }) => ({
  color: theme.colors.textGray,
  backgroundColor: theme.colors.textInputBackground,
  borderColor: hasError ? theme.colors.buttonAlert : theme.colors.textInputBackground,
  '&::placeholder': {
    color: disabled ? theme.colors.textDisabled : theme.colors.textLightGray,
  },
}));
