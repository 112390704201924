import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from '@components/button';
import TriggerButton from '@components/triggerButton';
import { TEXT_COLOR } from '@components/text';
import RefresherImg from '@assets/images/paths_refresher_home.svg';
import JuniorImg from '@assets/images/paths_junior_home.svg';
import ChronicImg from '@assets/images/paths_chronic_home.svg';
import { COURSE_TYPES } from '@utils/constants';

import {
  Wrapper, ContentWrapper, TextContainer, PathsContainer, Path, PathInfo, PathOptions, Price, StyledImage,
  PriceText,
} from './styles';

class PriceSection extends Component {
  constructor() {
    super();
    this.state = {
      [COURSE_TYPES.ACCELERATOR]: { isAnnuallyChecked: true },
      [COURSE_TYPES.JUNIOR]: { isAnnuallyChecked: true },
      [COURSE_TYPES.NOVOTALK]: { isAnnuallyChecked: true },
    };
  }

  handleTrigger = (type) => {
    this.setState((prevState) => (
      {
        [type]: {
          isAnnuallyChecked: !prevState[type].isAnnuallyChecked,
        },
      }
    ));
  }

  renderPath = (img, content, type) => {
    if (type !== COURSE_TYPES.NOVOTALK) return <div />;
    const { [type]: { isAnnuallyChecked } } = this.state;
    const { data } = this.props;
    const paymentData = data.find((item) => item.node.entry.courseType === type).node.entry.payment;
    return (
      <Path type={type}>
        <StyledImage src={img} alt="" type={type} />
        <PathInfo dangerouslySetInnerHTML={{ __html: content }} />
        <PathOptions>
          <TriggerButton
            triggerState={isAnnuallyChecked}
            handleTrigger={() => this.handleTrigger(type)}
            margin="0 0 26px 0"
          />
          <Price color={TEXT_COLOR.HIGHLIGHTED}>
            {`${paymentData[isAnnuallyChecked ? 'annually' : 'monthly']}$`}
          </Price>
          <PriceText fontSize={21} color={TEXT_COLOR.HIGHLIGHTED}>
            per month
          </PriceText>
        </PathOptions>
        <Button
          width={233}
          height={53}
          outline
          href="/sign-up/index.html"
        >
          Sign up
        </Button>
      </Path>
    );
  }

  render() {
    const { content } = this.props;
    const splittedContent = content.split('<hr>');
    return (
      <Wrapper>
        <ContentWrapper>
          <TextContainer dangerouslySetInnerHTML={{ __html: splittedContent[0] }} />
          <PathsContainer>
            {this.renderPath(JuniorImg, splittedContent[1], COURSE_TYPES.JUNIOR)}
            {this.renderPath(ChronicImg, splittedContent[2], COURSE_TYPES.NOVOTALK)}
            {this.renderPath(RefresherImg, splittedContent[3], COURSE_TYPES.ACCELERATOR)}
          </PathsContainer>
        </ContentWrapper>
      </Wrapper>
    );
  }
}

PriceSection.propTypes = {
  content: PropTypes.string,
  data: PropTypes.array,
};

export default PriceSection;
